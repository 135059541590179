export const stateAndCountryCoordinates = [
  { name: "Alabama", lat: 32.806671, lng: -86.79113 },
  { name: "Alaska", lat: 61.370716, lng: -152.404419 },
  { name: "American Samoa", lat: -14.271, lng: -170.1322 },
  { name: "Arizona", lat: 33.729759, lng: -111.431221 },
  { name: "Arkansas", lat: 34.969704, lng: -92.373123 },
  { name: "Calfornia", lat: 36.116203, lng: -119.681564 },
  { name: "California", lat: 36.7783, lng: -119.4179 },
  { name: "Chicago", lat: 41.8781, lng: -87.6298 },
  { name: "Christ Church", lat: 13.0672, lng: -59.5273 },
  { name: "Colorado", lat: 39.550051, lng: -105.782067 },
  { name: "Connecticut", lat: 41.597782, lng: -72.755371 },
  { name: "Florida", lat: 27.766279, lng: -81.686783 },
  { name: "Fort Myers", lat: 26.6406, lng: -81.8723 },
  { name: "Georgia", lat: 33.040619, lng: -83.643074 },
  { name: "Guam", lat: 13.4443, lng: 144.7937 },
  { name: "Hawaii", lat: 21.0943, lng: -157.4983 },
  { name: "Idaho", lat: 44.240459, lng: -114.478828 },
  { name: "Illinois", lat: 40.349457, lng: -88.986137 },
  { name: "Indiana", lat: 39.849426, lng: -86.258278 },
  { name: "Iowa", lat: 42.011539, lng: -93.210526 },
  { name: "Kansas", lat: 38.5266, lng: -96.7265 },
  { name: "Kentucky", lat: 37.66814, lng: -84.670067 },
  { name: "Louisiana", lat: 31.1696, lng: -91.8678 },
  { name: "Maine", lat: 44.693947, lng: -69.381927 },
  { name: "Marshall Islands", lat: 7.1315, lng: 171.1845 },
  { name: "Maryland", lat: 39.063946, lng: -76.802101 },
  { name: "Massachusetts", lat: 42.407211, lng: -71.382439 },
  { name: "Maui", lat: 20.7984, lng: -156.3319 },
  { name: "Michigan", lat: 44.94356, lng: -86.415805 },
  { name: "Minnesota", lat: 46.39241, lng: -94.63623 },
  { name: "Mississippi", lat: 32.741646, lng: -89.678696 },
  { name: "Missouri", lat: 38.456085, lng: -92.288368 },
  { name: "Montana", lat: 46.921925, lng: -110.454353 },
  { name: "Nebraska", lat: 41.12537, lng: -98.268082 },
  { name: "Nevada", lat: 38.313515, lng: -117.055374 },
  { name: "New Hampshire", lat: 43.452492, lng: -71.563896 },
  { name: "New Jersey", lat: 40.298904, lng: -74.521011 },
  { name: "New Mexico", lat: 34.840515, lng: -106.248482 },
  { name: "New York", lat: 43.165726, lng: -75.237691 },
  { name: "North Carolina", lat: 35.7596, lng: -79.0193 },
  { name: "North Dakota", lat: 47.5515, lng: -101.002 },
  { name: "North Mariana Islands", lat: 15.0979, lng: 145.6739 },
  { name: "Ohio", lat: 40.4173, lng: -82.9071 },
  { name: "Oklahoma", lat: 35.0078, lng: -97.0929 },
  { name: "Oregon", lat: 43.8041, lng: -120.5542 },
  { name: "Pennsylvania", lat: 41.2033, lng: -77.1945 },
  { name: "Puerto Rico", lat: 18.2208, lng: -66.5901 },
  { name: "Rhode Island", lat: 41.5801, lng: -71.4774 },
  { name: "South Carolina", lat: 33.8361, lng: -81.1637 },
  { name: "South Dakota", lat: 43.9695, lng: -99.9018 },
  { name: "Tennessee", lat: 35.5175, lng: -86.5804 },
  { name: "Texas", lat: 31.9686, lng: -99.9018 },
  { name: "Utah", lat: 39.32098, lng: -111.093731 },
  { name: "Vermont", lat: 44.5588, lng: -72.5778 },
  { name: "Virgin Island", lat: 18.3358, lng: -64.8963 },
  { name: "Virgin Islands", lat: 18.3358, lng: -64.8963 },
  { name: "Virginia", lat: 37.4316, lng: -78.6569 },
  { name: "Washington", lat: 47.7511, lng: -120.7401 },
  { name: "West Virginia", lat: 38.5976, lng: -80.4549 },
  { name: "Wisconsin", lat: 44.7863, lng: -89.826 },
  { name: "Wyoming", lat: 43.07597, lng: -107.290283 },
  { name: "Belize", lat: 17.1899, lng: -88.4976 },
  { name: "Canada", lat: 56.1304, lng: -106.3468 },
  { name: "Mexico", lat: 23.6345, lng: -102.5528 },
  { name: "Jalisco", lat: 20.6597, lng: -103.3496 },
  { name: "Guerrero", lat: 17.3616, lng: -99.748 },
  { name: "British Columbia", lat: 53.7267, lng: -127.6476 },
  { name: "Costa Rica", lat: 9.7489, lng: -83.7534 },
  { name: "Antigua and Barbuda", lat: 17.0608, lng: -61.7964 },
  { name: "Argentina", lat: -38.4161, lng: -63.6167 },
  { name: "Aruba", lat: 12.5211, lng: -69.9683 },
  { name: "Australia", lat: -25.2744, lng: 133.7751 },
  { name: "Bahamas", lat: 25.0343, lng: -77.3963 },
  { name: "Barbados", lat: 13.1939, lng: -59.5432 },
  { name: "Belgium", lat: 50.5039, lng: 4.4699 },
  { name: "Bermuda", lat: 32.3078, lng: -64.7505 },
  { name: "Bonaire", lat: 12.1784, lng: -68.2385 },
  { name: "Brazil", lat: -14.235, lng: -51.9253 },
  { name: "Grand Cayman", lat: 19.3221, lng: -81.2409 },
  { name: "Chile", lat: -35.6751, lng: -71.543 },
  { name: "Shanghai", lat: 31.2304, lng: 121.4737 },
  { name: "China", lat: 35.8617, lng: 104.1954 },
  { name: "Colombia", lat: 4.5709, lng: -74.2973 },
  { name: "Cuba", lat: 21.5218, lng: -77.7812 },
  { name: "Curacao", lat: 12.1696, lng: -68.99 },
  { name: "Dominican Republic", lat: 18.7357, lng: -70.1627 },
  { name: "Ecuador", lat: -1.8312, lng: -78.1834 },
  { name: "El Salvador", lat: 13.7942, lng: -88.8965 },
  { name: "France", lat: 46.6034, lng: 1.8883 },
  { name: "Germany", lat: 51.1657, lng: 10.4515 },
  { name: "Genada", lat: 12.1165, lng: -61.679 },
  { name: "Guatemala", lat: 15.7835, lng: -90.2308 },
  { name: "Guyana", lat: 4.8604, lng: -58.9302 },
  { name: "Haiti", lat: 18.9712, lng: -72.2852 },
  { name: "Honduras", lat: 15.2, lng: -86.2419 },
  { name: "India", lat: 20.5937, lng: 78.9629 },
  { name: "Ireland", lat: 53.1424, lng: -7.6921 },
  { name: "Israel", lat: 31.0461, lng: 34.8516 },
  { name: "Italy", lat: 41.8719, lng: 12.5674 },
  { name: "Jamaica", lat: 18.1096, lng: -77.2975 },
  { name: "Japan", lat: 36.2048, lng: 138.2529 },
  { name: "Mexico City", lat: 19.4326, lng: -99.1332 },
  { name: "Oaxaca", lat: 17.0732, lng: -96.7266 },
  { name: "Netherlands", lat: 52.1326, lng: 5.2913 },
  { name: "New Zealand", lat: -40.9006, lng: 174.886 },
  { name: "Nicaragua", lat: 12.8654, lng: -85.2072 },
  { name: "Panama", lat: 8.5379, lng: -80.7821 },
  { name: "Peru", lat: -9.1899, lng: -75.0152 },
  { name: "Qatar", lat: 25.3548, lng: 51.1839 },
  { name: "Saint Kitts and Nevis", lat: 17.3578, lng: -62.783 },
  { name: "Saint Lucia", lat: 13.9094, lng: -60.9789 },
  { name: "Saint Vincent and the Grenadines", lat: 12.9843, lng: -61.2872 },
  { name: "Sint Maarten", lat: 18.0425, lng: -63.0548 },
  { name: "South Korea", lat: 35.9078, lng: 127.7669 },
  { name: "Spain", lat: 40.4637, lng: -3.7492 },
  { name: "Switzerland", lat: 46.8182, lng: 8.2275 },
  { name: "Trinidad and Tobago", lat: 10.6918, lng: -61.2225 },
  { name: "Turks and Caicos Islands", lat: 21.694, lng: -71.7979 },
  { name: "England", lat: 52.3555, lng: -1.1743 },
  { name: "Martinique", lat: 14.6415, lng: -61.0242 },
  { name: "Greece", lat: 39.0742, lng: 21.8243 },
  { name: "Lisbon", lat: 38.7223, lng: -9.1393 },
  { name: "Uruguay", lat: -32.5228, lng: -55.7658 },
  { name: "Austria", lat: 47.5162, lng: 14.5501 },
  { name: "Ghana", lat: 7.9465, lng: -1.0232 },
  { name: "Nigeria", lat: 9.0819, lng: 8.6753 },
  { name: "Senegal", lat: 14.4974, lng: -14.4524 },
  { name: "South Africa", lat: -30.5595, lng: 22.9375 },
  { name: "Sweden", lat: 60.1282, lng: 18.6435 },
  { name: "Scotland", lat: 56.4907, lng: -4.2026 },
  { name: "French Polynesia", lat: -17.6797, lng: -149.4068 },
  { name: "Czech Republic", lat: 49.8175, lng: 15.473 },
  { name: "Denmark", lat: 56.2639, lng: 9.5018 },
  { name: "Iceland", lat: 64.9631, lng: -19.0208 },
  { name: "Bahamas", lat: 25.0343, lng: -77.3963 },
  { name: "Guatemala", lat: 15.7835, lng: -90.2308 },
  { name: "Christ Church", lat: 13.0672, lng: -59.5273 },
  { name: "Colombia", lat: 4.5709, lng: -74.2973 },
  { name: "Grenada", lat: 12.1165, lng: -61.679 },
  { name: "Guadeloupe", lat: 16.265, lng: -61.551 },
  { name: "Cayman Islands", lat: 19.3133, lng: -81.2546 },
  { name: "Micronesia", lat: 6.8875, lng: 158.215 },
  { name: "Micronesia", lat: 7.4256, lng: 150.5508 },
  { name: "Brandenburg", lat: 52.4125, lng: 12.5316 },
  { name: "Hong Kong", lat: 22.3193, lng: 114.1694 },
  { name: "India", lat: 20.5937, lng: 78.9629 },
  { name: "Jordan", lat: 30.5852, lng: 36.2384 },
  { name: "Nigeria", lat: 9.0819, lng: 8.6753 },
  { name: "Palau", lat: 7.515, lng: 134.5825 },
  { name: "Philippines", lat: 12.8797, lng: 121.774 },
  { name: "Singapore", lat: 1.3521, lng: 103.8198 },
  { name: "Taiwan", lat: 23.6978, lng: 120.9605 },
  { name: "Croatia", lat: 45.1, lng: 15.2 },
  { name: "Norway", lat: 60.472, lng: 8.4689 },
  { name: "Portugal", lat: 39.3999, lng: -8.2245 },
  { name: "St. Kitts and Nevis", lat: 17.3578, lng: -62.783 },
];
